//react
import React, { useState, useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

//components
import AssetReturnedSection from "./AssetReturnedSection";
import RentalDetails from "./RentalDetails";
import RentalCard from "./RentalCard";
import RentalDetailsLoading from "./RentalDetailsLoading";
import RentalShimmerCard from "./common/RentalShimmerCard";
import PageNotFound from "../PageNotFound";
import Overlay from "../../common/overlay/Overlay";
import Header from "../../common/overlay/Header";
import SubHeader from "../../common/overlay/Subheader";
import RentalStatusIcon from "./common/RentalStatusIcon";

//styles
import styled, { useTheme } from "../../../styling/styled-components";

//utils
import { prettifyDate, prettifyUID } from "../../../utils/helpers";

//apis
import * as queries from "../../../graphql/queries";
import { useQuery } from "../../../graphql/hooks/query";
import queryString from "query-string";

const HeaderTextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
`;

const HeaderBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

// TODO: We should fix the paddings here so that it's using a theme value
// instead of 40px (which isn't in the theme). Right now there's other issues
// with this overlay that break if we try to make this change. So we need to
// fix those things first
const SectionContainer = styled.div`
    padding-left: 40px;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

export default function RentalOverlay({
    onRentalClose,
    openConsumerOverlay,
    setBackButton,
    onFilterOpen,
    rentalId,
    updatedRentalStatus,
    setConsumerId,
    setFromRentalDetails,
    rentalOpen,
    onClickDisplay,
    width,
}) {
    const { spacing, colors } = useTheme();
    let [searchParams, setSearchParams] = useSearchParams();
    const [isComponentVisible, setComponentVisible] = useState(false);
    const [getRental, getRentalData, getRentalLoading, getRentalErrors] =
        useQuery(queries.getRental);
    const [getConsumer, getConsumerData] = useQuery(queries.getConsumer);
    const [getRentalConfig, getRentalConfigData] = useQuery(
        queries.getRentalConfig
    );
    const location = useLocation();
    const queryParams = queryString.parse(location.search);

    useEffect(() => {
        if (rentalId && queryParams.rentalId !== rentalId && rentalOpen) {
            setSearchParams(`?${new URLSearchParams({ rentalId: rentalId })}`);
        }
    }, [rentalId]);

    useEffect(() => {
        getRental({ rentalId: rentalId });
    }, [rentalId]);

    useEffect(() => {
        if (getRentalData) {
            getConsumer({ consumerId: getRentalData.consumerId });
            getRentalConfig({
                rentalConfigId: getRentalData.rentalConfigId,
            });
        }
    }, [getRentalData]);

    useEffect(() => {
        updateAndSetData();
    }, [getConsumerData, getRentalConfigData]);

    useEffect(() => {
        if (updatedRentalStatus) {
            loadCancelledData();
        }
    }, [updatedRentalStatus]);

    const loadCancelledData = async () => {
        getRental({ rentalId: rentalId });
    };

    const updateAndSetData = () => {
        if (getRentalData && getConsumerData && getRentalConfigData) {
            setComponentVisible(true);
        }
    };

    const clearParamsAndCloseOverlay = () => {
        onRentalClose();
        searchParams.delete("consumerId");
        searchParams.delete("rentalId");
        setSearchParams(searchParams);
    };

    const rentalInitiatedSubheader = () => {
        const assetType = getRentalData.rentedAssetTypeName
            ? getRentalData.rentedAssetTypeName
            : "Unkown Asset";
        return (
            assetType +
            " checked-out on " +
            (getRentalData.createdAt && prettifyDate(getRentalData.createdAt))
        );
    };

    const objectDoesntExistError = getRentalErrors
        ? getRentalErrors?.errors[0]?.errorType == "object-does-not-exist"
        : null;

    return (
        <>
            {rentalOpen && (
                <Overlay
                    scroll={"scroll"}
                    onClick={clearParamsAndCloseOverlay}
                    onClickedOutside={clearParamsAndCloseOverlay}
                    width={width}
                >
                    <div style={{ paddingBottom: spacing.huge }}>
                        {getRentalErrors && !getRentalData ? (
                            objectDoesntExistError ? (
                                <PageNotFound />
                            ) : (
                                <></>
                            )
                        ) : !isComponentVisible ? (
                            <>
                                <HeaderBox>
                                    <RentalShimmerCard></RentalShimmerCard>
                                    <HeaderTextBox></HeaderTextBox>
                                </HeaderBox>
                                <RentalDetailsLoading
                                    rental={getRentalData}
                                ></RentalDetailsLoading>
                            </>
                        ) : (
                            <>
                                <div>
                                    <HeaderBox>
                                        <RentalStatusIcon
                                            status={getRentalData?.status}
                                        />
                                        <HeaderTextBox>
                                            <Header
                                                color={colors.DEEP_BLUE_SEA}
                                            >
                                                Rental #
                                                {prettifyUID(
                                                    getRentalData.rentalId
                                                )}
                                            </Header>
                                            <SubHeader
                                                color={colors.DEEP_BLUE_SEA}
                                            >
                                                {rentalInitiatedSubheader()}
                                            </SubHeader>
                                        </HeaderTextBox>
                                    </HeaderBox>
                                </div>
                                <RentalDetails
                                    setBackButton={setBackButton}
                                    setConsumerId={setConsumerId}
                                    consumer={getConsumerData}
                                    rental={getRentalData}
                                    openConsumerOverlay={openConsumerOverlay}
                                    onFilterOpen={onFilterOpen}
                                    rentalConfig={getRentalConfigData}
                                    setFromRentalDetails={setFromRentalDetails}
                                ></RentalDetails>
                                <RentalCard
                                    rental={getRentalData}
                                    onClickDisplay={onClickDisplay}
                                ></RentalCard>
                                <SectionContainer>
                                    <AssetReturnedSection
                                        rental={getRentalData}
                                    />
                                </SectionContainer>
                            </>
                        )}
                    </div>
                </Overlay>
            )}
        </>
    );
}
