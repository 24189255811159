//react
import { useState, useEffect, useContext, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

//api
import * as queries from "../../graphql/queries";
import useGraphClient from "../../hooks/useGraphClient";
import { RentalSearchDocument, RentalStatus } from "../../API";

//style
import styled, { useTheme, css } from "styled-components/macro";
import { customStyles } from "../common/TableStyles";

//utils
import { parseISO, format } from "date-fns";
import {
    prettifyUID,
    prettifyPhoneNumber,
    truncateString,
    isEmpty,
} from "../../utils/helpers";
import queryString from "query-string";
import { truncateConsumer } from "../../utils/consumers/truncateConsumer";
import formatCount from "../../utils/formatCount";
import { removePrefix } from "../../utils/activity/removePrefix";
import { displayLastPhaseLabel } from "../../utils/activity/displayLastPhaseLabel";
import useClickOutside from "../../utils/useClickOutside";

//components
import AssetReceivedFilter from "./rentals/AssetReceivedFilter";
import AssetReceivedSubheader from "./rentals/AssetReceivedSubheader";
import Text from "../common/Text";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import Filter, { FilterOption } from "../common/Filter";
import EventSearch, { SearchTag } from "../common/EventSearch";
import ScreenViewTable from "../common/ScreenViewTable";
import LinkText from "../common/LinkText";
import OverlayTakeover from "../overlay/OverlayTakeover";
import RentalOverlay from "../overlay/rental/RentalOverlay";
import { toastError, toastSuccess } from "../../utils/toasts";
import CancelModal from "../overlay/rental/cancelRental/CancelModal";
import RentalViusal from "../rentalsVisual/RentalVisual";
import StatusLabel from "./status/StatusLabel";
import AssetReceivedWarning from "./rentals/AssetReceivedWarning";
import Displayable from "../common/Displayable";
import AssetReceivedAlert from "./rentals/AssetReceivedAlert";

//constants
import { RENTAL_LIST } from "../../constants/activity";
import { STATUS_LIST } from "../../constants/statusList";
import { searchTags } from "../../constants/searchTags";
import { orderedRentalPhases } from "../../constants/rentalVisual/orderedRentalPhases";
import { RentalPhase, ChargeStatus } from "../../API";

//images
import { ReactComponent as Close } from "../../assets/vectors/Close.svg";

//context
import AssetsContext from "../../contexts/AssetsContext";
import NoTableData from "../common/table/NoTableData";

const MAX_RETURNED_RESULTS = 10000;

const Container = styled(ScreenViewTable)``;

const SearchWrapper = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const StyledEventSearch = styled(EventSearch)`
    width: 432px;
`;

const HeaderCellText = styled(Text)`
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

const CellText = styled(Text)``;

const CellSubText = styled(Text)``;

const Counter = styled.div`
    border-radius: 4px;
    padding: ${({ theme }) => theme.spacing.xsmall}px
        ${({ theme }) => theme.spacing.small}px;
    margin-left: ${({ theme }) => theme.spacing.small}px;
    height: 25px;
    max-width: 70px;
    white-space: nowrap;
`;

const CounterValue = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    text-align: center;
`;

const FilterLeft = styled.div`
    display: flex;
`;

const FilterWrapper = styled.div`
    padding-right: ${({ theme }) => theme.spacing.small}px;
    @media (max-width: 820px) {
        ${(props) =>
            props.firstFilter
                ? `padding-right: ${0}px;`
                : `padding-right: ${props.theme.spacing.medium}px`};
    }
`;

const FilterRight = styled.div`
    display: flex;
`;

const TopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    flex-direction: row;
`;

const FilterPill = styled.span`
    background-color: ${({ theme }) => theme.colors.WAVE_STORM};
    border-radius: 100px;
    padding: ${({ theme }) => theme.spacing.tiny}px
        ${({ theme }) => theme.spacing.xsmall}px;
    display: flex;
    width: fit-content;
    margin-right: ${({ theme }) => theme.spacing.small}px;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    cursor: pointer;
    svg {
        cursor: pointer;
    }
`;

const PillText = styled(Text)`
    svg {
        position: relative;
        top: 2px;
        padding-left: ${({ theme }) => theme.spacing.tiny}px;
        color: ${({ theme }) => theme.colors.SUMMER_STORM};
    }
`;

const FilterPillContainer = styled.div`
    padding-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
`;

const ClearAllText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    cursor: pointer;
`;

const TooMuchDataText = styled(Text)`
    padding: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.xlarge}px;
    text-align: center;
`;

const TabletResponsiveFilters = styled.div`
    display: flex;
    flex-direction: row;
    ${({ theme }) =>
        theme.isTablet || theme.isMobile
            ? `flex-direction: column;
    gap: ${theme.spacing.small}px;`
            : ""}
    }
`;

const AssetAlertDiv = styled.div`
    white-space: normal;
    word-wrap: break-word;
`;

type Props = {
    fromTimestamp?: string;
    toTimestamp?: string;
};

export default function RentalsList({
    fromTimestamp,
    toTimestamp,
    consumerIdProp,
    updateConsumer,
    onClickDisplay,
    ...props
}: Props) {
    let [searchParams, setSearchParams] = useSearchParams();
    const [configsLoading, setConfigsLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(true);
    const [data, setData] = useState(null);
    const [rentalPhases, setRentalPhases] = useState([]);
    const assetContext = useContext(AssetsContext);
    const { assets } = assetContext.selections;
    const [phaseFilters, setPhaseFilters] = useState([]);
    const [check, setCheck] = useState(false);

    const [isShowing, setIsShowing] = useState(false);
    const [consumerIsShowing, setConsumerIsShowing] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [rentalOpen, setRentalOpen] = useState(false);
    const [rentalLoaded, setRentalLoaded] = useState(false);
    const [rental, setRental] = useState({});
    const [clickedRental, setClickedRental] = useState({});
    const [panelWidth, setPanelWidth] = useState(0);
    const [rentalWidth, setRentalWidth] = useState(0);
    const [rentalId, setRentalId] = useState("");
    const [consumerId, setConsumerId] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [fromRentalDetails, setFromRentalDetails] = useState(false);
    const [backButtonClicked, setBackButtonClicked] = useState(false);
    const [linkClicked, setLinkClicked] = useState(0);
    const [highlight, setHighlight] = useState({});
    const location = useLocation();
    const [checkProps, setCheckProps] = useState(false);
    const [cancelRentalNotification, setCancelRentalNotification] = useState(
        {}
    );
    const [loading, setLoading] = useState(false);
    const [updatedRentalStatus, setUpdatedRentalStatus] = useState(false);
    const queryParams = queryString.parse(location.search);
    const [isInitiated, setIsInitiated] = useState(false);
    const propValue = queryParams.prop;
    let removePrefixPropValue = removePrefix(propValue, "consumerId=");

    const graphClient = useGraphClient();

    const sortOrder = [
        "Initiated",
        "Late pending",
        "Late",
        "About to expire",
        "Expired",
        "Fined (manual)",
        "Fined (automatic)",
        "Fine error",
    ];

    const loadRentalConfigs = async () => {
        try {
            const results = await graphClient.graphql({
                query: queries.listRentalConfigs,
                variables: {
                    latestOnly: false,
                },
            });
            const rentalConfigs = results.data.listRentalConfigs;
            setRentalConfigs(rentalConfigs);
        } catch (error) {
            console.error("Error listing rentalConfigs", error);
        } finally {
            setConfigsLoading(false);
        }
    };

    const loadRentalPhases = async () => {
        try {
            const results = await graphClient.graphql({
                query: queries.listOrganizationRentalPhases,
            });

            const phase = results.data.listOrganizationRentalPhases;
            let filteredPhase = [];

            phase.forEach((phase, index) => {
                //will be able to remove filter out logic once we get rid of Fined/Failed and Fined/Failed Pending
                //TEC-1861 notion ticket
                let filterOutOldPhases =
                    phase == RentalPhase.FinePending ||
                    phase == RentalPhase.FailedPending ||
                    phase == RentalPhase.Fined ||
                    phase == RentalPhase.Failed;
                if (filterOutOldPhases) {
                    return;
                } else {
                    let lowerLabel = displayLastPhaseLabel(phase);
                    let rental = {
                        label: lowerLabel,
                        value: phase,
                        selected: false,
                    };
                    filteredPhase.push(rental);
                }
            });
            setRentalPhases(filteredPhase);

            let phaseFiltersUpdated = [...rentalPhases].map((filter) =>
                searchParams.getAll("phase")?.includes(filter.value)
                    ? { ...filter, selected: true }
                    : filter
            );
            phaseFiltersUpdated.sort((a, b) => {
                return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
            });
            setPhaseFilters(phaseFiltersUpdated);
            setCheck(true);
        } catch (error) {
            console.error("Error listing in loadRentalPhases", error);
        }
    };

    const consumerSideBar = (row, object) => {
        openConsumerOverlay();
        setLinkClicked(linkClicked + 1);
        setConsumerId(row.consumerId);
        handleRowClicked(object);
        setHighlight(object);
    };

    const rentalSideBar = (row) => {
        openOverlay();
        setLinkClicked(linkClicked + 1);
        setRentalId(row.rentalId);
        setRentalLoaded(true);
        setClickedRental(row);
        handleRowClicked(row);
        setHighlight(row);
    };

    const openOverlay = () => {
        setTimeout(() => {
            setIsShowing(true);
        }, 50);
        setRentalOpen(true);
    };

    const closeOverlay = () => {
        setTimeout(() => {
            setRentalOpen(false);
        }, 50);
        setIsShowing(false);
    };

    const openConsumerOverlay = () => {
        setTimeout(() => {
            setIsOpen(true);
        }, 50);
        setConsumerIsShowing(true);
    };

    const closeConsumerOverlay = () => {
        setTimeout(() => {
            setIsOpen(false);
        }, 50);
        setConsumerIsShowing(false);
    };

    const ASSET_FILTERS = assets.map((asset) => ({
        label: asset.name,
        value: asset.assetTypeId,
        selected: false,
    }));

    const [dateRange, setDateRange] = useState(
        searchParams.getAll("date") || [fromTimestamp, toTimestamp]
    );

    const [statusFilters, setStatusFilters] = useState(
        [...STATUS_LIST].map((filter) =>
            searchParams.getAll("status")?.includes(filter.value)
                ? { ...filter, selected: true }
                : filter
        )
    );

    const [assetFilters, setAssetFilters] = useState([]);

    const [assetReceivedFilter, setAssetReceivedFilter] = useState("");

    const [rowsPerPage, setRowsPerPage] = useState(
        searchParams.get("rowsPerPage") || 50
    );
    const [page, setPage] = useState(searchParams.get("page") || 1);
    const [searchTerm, setSearchTerm] = useState(
        searchParams.get("search") || ""
    );
    const [rentalConfigs, setRentalConfigs] = useState(null);
    const activityContainer = useRef(null);
    const eventSearch = useRef(null);

    const { colors, isDesktop, isTablet, isMobile, spacing } = useTheme();

    useEffect(() => {
        window.addEventListener("popstate", refreshOnBackClick);
        return () => {
            window.removeEventListener("popstate", refreshOnBackClick);
        };
    }, []);

    const refreshOnBackClick = () => {
        window.location.reload();
    };

    const clearSearchTerm = () => {
        setSearchTerm("");
    };

    useEffect(() => {
        if (assetFilters.length == 0) {
            setAssetFilters(
                [...ASSET_FILTERS].map((filter) =>
                    searchParams.getAll("asset")?.includes(filter.value)
                        ? { ...filter, selected: true }
                        : filter
                )
            );
        }
    }, [assets]);

    useEffect(() => {
        loadRentalConfigs();
        loadRentalPhases();
    }, [check]);

    useEffect(() => {
        loadData();
        if (propValue && !checkProps) {
            setSearchTerm(removePrefixPropValue);
            setCheckProps(true);
        }
    }, [
        rentalPhases,
        phaseFilters,
        statusFilters,
        assetFilters,
        assetReceivedFilter,
        page,
        rowsPerPage,
        dateRange,
        searchTerm,
        cancelRentalNotification,
    ]);

    useEffect(() => {
        if (isOpen) {
            setPanelWidth("516px");
            setRentalWidth(0);
        } else if (rentalOpen) {
            setRentalWidth("516px");
            setPanelWidth(0);
        } else {
            setPanelWidth(0);
            setRentalWidth(0);
        }
    }, [isOpen, rentalOpen, openModal]);

    useEffect(() => {
        if (queryParams.consumerId) {
            consumerSideBar(queryParams);
            setConsumerIsShowing(true);
            closeOverlay();
        }
        if (queryParams.rentalId && !sessionStorage.getItem("is_reloaded")) {
            setRentalId(queryParams.rentalId);
            openOverlay();
            setIsOpen(false);
            return;
        }
    }, [queryParams.rentalId, queryParams.consumerId, location.search]);

    useEffect(() => {
        if (!queryParams.rentalId || location.search.includes(`rentalId=%`)) {
            return;
        } else if (
            location.search.includes(`rentalId=`) &&
            location.search.includes(`page`)
        ) {
            searchParams.delete("rentalId");
            setSearchParams(searchParams);
            return;
        }
    }, [location.search]);

    useEffect(() => {
        if (
            !queryParams.consumerId ||
            location.search.includes(`consumerId=%`)
        ) {
            return;
        } else if (location.search.includes(`consumerId=`)) {
            consumerSideBar(queryParams);
        }
    }, [location.search]);

    useEffect(() => {
        setDateRange([fromTimestamp, toTimestamp]);
    }, [fromTimestamp, toTimestamp]);

    // [TEC-1875] return received date range alert;
    // TODO: remove this when the date range can no longer include this;
    //  problem time period. 18 months after 3/14/24 or 9/14/15.
    const [displayWarningIcon, setDisplayWarningIcon] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const returnReceivedReleaseDate = new Date("2024-03-15T00:00:00.000Z");
    const fromDateApplied = new Date(dateRange[0]);

    const popUpRef = useRef("asset-received-alert");

    const displayReturnReceivedAlert = () => {
        setShowAlert(true);
    };

    const checkIfDateRangeBeforeReturnReceivedRelease = () => {
        if (fromDateApplied < returnReceivedReleaseDate) {
            setDisplayWarningIcon(true);
        } else {
            setDisplayWarningIcon(false);
            setShowAlert(false);
        }
    };

    useClickOutside(
        popUpRef,
        () => {
            setShowAlert(false);
        },
        []
    );

    useEffect(() => {
        checkIfDateRangeBeforeReturnReceivedRelease();
    }, [fromTimestamp, toTimestamp, dateRange]);

    const ReturnReceivedMessage = ({ row }) => {
        return (
            <Displayable
                displayPrimaryComponent={
                    rental.rentals
                        ? rental?.rentals[0]?.rentalId == row.rentalId &&
                          displayWarningIcon
                        : false
                }
            >
                <AssetReceivedWarning
                    displayReturnReceivedAlert={displayReturnReceivedAlert}
                    showDefaultAlert={showAlert}
                />
                <AssetAlertDiv>
                    <AssetReceivedAlert
                        showAlert={showAlert}
                        popUpRef={popUpRef}
                    />
                </AssetAlertDiv>
            </Displayable>
        );
    };

    //will need to be updated once we remove expired as a status
    //TEC-1856 notion ticket
    const filterSelectedStatusRentals = () => {
        return statusFilters
            .filter((status) => status.selected)
            .flatMap((status) => {
                if (status.value == RentalStatus.Failed) {
                    return [RentalStatus.Failed, RentalStatus.Expired];
                } else {
                    return status.value;
                }
            });
    };

    //will need to be updated once we remove failed/fined and failed/fined pending phases
    //TEC-1861 notion ticket
    const filterSelectedPhaseRentals = () => {
        return phaseFilters
            .filter((status) => status.selected)
            .flatMap((status) => {
                if (status.value == RentalPhase.Expired) {
                    return [
                        RentalPhase.Fined,
                        RentalPhase.Failed,
                        RentalPhase.Expired,
                    ];
                }
                if (status.value == RentalPhase.AboutToExpire) {
                    return [
                        RentalPhase.FinePending,
                        RentalPhase.FailedPending,
                        RentalPhase.AboutToExpire,
                    ];
                } else {
                    return status.value;
                }
            });
    };

    const loadData = async () => {
        setDataLoading(true);
        if (dateRange[1] && dateRange[0]) {
            try {
                const userTimezone =
                    Intl.DateTimeFormat().resolvedOptions().timeZone;
                const results = await graphClient.graphql({
                    query: queries.searchRentals,
                    variables: {
                        fromTimestamp: new Date(dateRange[0]).toISOString(),
                        toTimestamp: new Date(dateRange[1]).toISOString(),
                        rentalPhases: filterSelectedPhaseRentals(),
                        assetTypeIds: assetFilters
                            .filter((asset) => asset.selected)
                            .map((asset) => asset.value),
                        rentalStatuses: filterSelectedStatusRentals(),
                        searchQuery: buildSearchQuery(),
                        size: rowsPerPage,
                        paginateFrom: (page - 1) * rowsPerPage,
                    },
                });
                const rentals = results.data.searchRentals;
                setData(rentals);
                setRental(rentals);
                setIsInitiated(true);
            } catch (error) {
                console.error("Error listing rentals", error);
            } finally {
                setDataLoading(false);
            }
        }
    };

    const buildSearchQuery = () => {
        let searchQuery = "";
        if (!!searchTerm) {
            searchQuery = searchTerm;
        }
        if (!!assetReceivedFilter) {
            // [TEC-1875] return received date range alert;
            // TODO: remove this when the date range can no longer include this;
            //  problem time period. 18 months after 3/14/24 or 9/14/15.
            if (!!searchQuery) {
                searchQuery =
                    "receivedAsset.assetId: * AND (" + searchQuery + ")";
            } else {
                searchQuery = `createdAt:>=2024-03-05 AND receivedAsset.assetId: *`;
            }
            if (assetReceivedFilter == "false") {
                searchQuery = `createdAt:>=2024-03-05 AND -receivedAsset.assetId:* `;
            }
        }
        return searchQuery;
    };

    const secondaryConsumerId = (row: RentalSearchDocument) => {
        if (row.consumer?.name) {
            return (
                prettifyPhoneNumber(row.consumer?.phoneNumber) ||
                row.consumer?.email ||
                prettifyUID(row.consumer?.consumerId)
            );
        } else if (row.consumer?.phoneNumber) {
            return row.consumer?.email || prettifyUID(row.consumer?.consumerId);
        } else return "";
    };

    const textSize = isMobile || isTablet ? "tiny" : "medium";

    const truncatePhoneNumber = (number) => {
        if (number) {
            return truncateString(
                prettifyPhoneNumber(number),
                isDesktop ? 17 : 12
            );
        } else return false;
    };

    const displayUpdatedCurrentPhaseLabel = (row) => {
        let charge = row.charge;
        let lastPhase = row.lastPhase;
        let createdAt = row.createdAt;

        //TODO: Update string to release date TEC-1914
        const createdAtDate = new Date(createdAt);
        const releaseDate = new Date("2024-08-07T00:00:00.000Z");
        if (row.charge) {
            if (row.charge) {
                if (
                    charge.chargeStatus == ChargeStatus.Pending ||
                    charge.chargeStatus == ChargeStatus.Failed
                ) {
                    if (createdAtDate < releaseDate) {
                        return "Fined (manual)";
                    }
                }
                if (charge.chargeStatus == ChargeStatus.Success) {
                    if (createdAtDate < releaseDate) {
                        return "Fined (automatic)";
                    }
                }
            }
        }
        return displayLastPhaseLabel(lastPhase || "-");
    };

    const columns = [
        {
            name: RENTAL_LIST.LAST_UPDATED,
            width: "17%",
            selector: (row: RentalSearchDocument) => (
                <>
                    <HeaderCellText
                        type="body"
                        size={"tiny"}
                        color={colors.EXHAUST}
                    >
                        {format(parseISO(row.updatedAt), "MM/dd/yy")}
                    </HeaderCellText>
                    <CellSubText
                        type="body"
                        size={"tiny"}
                        color={colors.EXHAUST}
                    >
                        {format(parseISO(row.updatedAt), "h:mm aaaaa'm'")}
                    </CellSubText>
                </>
            ),
        },
        {
            name: RENTAL_LIST.RENTAL_ID,
            width: isDesktop ? "17%" : "20%",
            selector: (row: RentalSearchDocument) => (
                <div>
                    <LinkText
                        data-tag="allowRowEvents"
                        type="new_link"
                        size={textSize}
                        onClick={() => rentalSideBar(row)}
                    >
                        {prettifyUID(row.rentalId)}
                    </LinkText>
                    <CellSubText
                        type="body"
                        size={"tiny"}
                        color={colors.EXHAUST}
                    >
                        {`${row.rentalConfig.name} ${row.rentalConfig.version}`}
                    </CellSubText>
                </div>
            ),
        },
        {
            name: RENTAL_LIST.ASSET_RENTED,
            grow: isDesktop ? 0.7 : 0.4,
            width: isDesktop ? "17%" : "16%",
            cell: (row: RentalSearchDocument) => (
                <div>
                    <ReturnReceivedMessage row={row} />
                    <HeaderCellText
                        type="label"
                        size="small"
                        color={colors.EXHAUST}
                    >
                        {isDesktop
                            ? row.rentedAsset?.assetType?.name
                            : row.rentedAsset?.assetType?.name?.slice(0, 15)}
                    </HeaderCellText>
                    <AssetReceivedSubheader rental={row} />
                </div>
            ),
        },
        {
            name: RENTAL_LIST.CONSUMER,
            width: isDesktop ? "18%" : "20%",
            cell: (row: RentalSearchDocument, index, column, id) =>
                row.consumer ? (
                    <div>
                        <HeaderCellText
                            type="link"
                            size="small"
                            color={colors.EXHAUST}
                        >
                            <LinkText
                                type="new_link"
                                size={textSize}
                                data-tag="allowRowEvents"
                                onClick={() =>
                                    consumerSideBar(row.consumer, row)
                                }
                            >
                                {truncateConsumer(
                                    row.consumer.name,
                                    !isDesktop,
                                    16
                                ) ||
                                    truncatePhoneNumber(
                                        row.consumer.phoneNumber
                                    ) ||
                                    prettifyUID(row.consumer.consumerId)}
                            </LinkText>
                        </HeaderCellText>

                        <CellSubText
                            type="body"
                            size="tiny"
                            color={colors.EXHAUST}
                        >
                            {isDesktop
                                ? secondaryConsumerId(row)
                                : truncateString(secondaryConsumerId(row), 14)}
                        </CellSubText>
                    </div>
                ) : (
                    <div>-</div>
                ),
        },
        {
            name: RENTAL_LIST.LAST_PHASE,
            width: isDesktop ? "17%" : "16%",
            selector: (row: RentalSearchDocument) => (
                <CellText type="label" size="small" color={colors.EXHAUST}>
                    {displayUpdatedCurrentPhaseLabel(row)}
                </CellText>
            ),
        },

        {
            name: RENTAL_LIST.STATUS,
            selector: (row: RentalSearchDocument) => (
                <CellText type="label" size="small" color={colors.EXHAUST}>
                    <StatusLabel
                        status={
                            row.status == RentalStatus.Expired
                                ? RentalStatus.Failed
                                : row.status
                        }
                    ></StatusLabel>
                </CellText>
            ),
            allowOverflow: true,
            width: "17%",
        },
    ];

    let mobileColumns = [...columns];
    mobileColumns.splice(2, 1);

    const handleOnEventClear = (
        clearFilters: (options: FilterOption[]) => void,
        defaultOptions: FilterOption[],
        type: string
    ) => {
        clearFilters(defaultOptions);
        searchParams.delete(type);
        setSearchParams(searchParams);
    };

    const handleOnEventSave = (
        setFilters: (options: FilterOption[]) => void,
        options: FilterOption[],
        type: string
    ) => {
        setFilters([...options]);

        searchParams.delete(type);
        options
            .filter((filterOption) => filterOption.selected)
            .forEach((filterOption) =>
                searchParams.append(type, filterOption.value)
            );

        setSearchParams(searchParams);
    };

    const handleAssetReceivedFilterUpdate = (selection: string) => {
        setAssetReceivedFilter(selection);
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
        term ? searchParams.set("search", term) : searchParams.delete("search");
        setSearchParams(searchParams);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setRowsPerPage(newPerPage);
        searchParams.set("rowsPerPage", newPerPage);
        setSearchParams(searchParams);
    };

    const handlePageChange = (page) => {
        setPage(page);
        searchParams.set("page", page);
        setSearchParams(searchParams);
        activityContainer.current?.scrollIntoView();
    };

    const filterLabel = (filters: FilterOption[]) => {
        const numberFiltersActivated = filters.reduce(
            (acc, cur) => (cur.selected ? acc + 1 : acc),
            0
        );
        return numberFiltersActivated > 0 ? `(${numberFiltersActivated})` : "";
    };

    const togglePillSelectedState = (str, arr, type, setFilters) => {
        setFilters(
            arr.map((obj) => {
                if (obj.value === str) {
                    return {
                        value: obj.value,
                        label: obj.label,
                        selected: !obj.selected,
                    };
                }
                return obj;
            })
        );
        const newParams = searchParams
            .getAll(type)
            .filter((param) => param != str);
        searchParams.delete(type);
        newParams.forEach((param) => searchParams.append(type, param));
        setSearchParams(searchParams);
    };

    let filterList = [];
    filterList = filterList.concat(
        phaseFilters.map((filter) => ({
            ...filter,
            onChange: setPhaseFilters,
            type: "phase",
            filters: phaseFilters,
        }))
    );
    filterList = filterList.concat(
        statusFilters.map((filter) => ({
            ...filter,
            onChange: setStatusFilters,
            type: "status",
            filters: statusFilters,
        }))
    );
    filterList = filterList.concat(
        assetFilters.map((filter) => ({
            ...filter,
            onChange: setAssetFilters,
            type: "asset",
            filters: assetFilters,
        }))
    );
    const selectedFilters = filterList.filter((filter) => filter.selected);

    const activatedFilters = () => {
        let numFilters = assetReceivedFilter == "" ? 0 : 1;
        numFilters += selectedFilters.length;
        return numFilters > 0 ? (
            <FilterPillContainer>
                {selectedFilters.map((activatedFilter) => (
                    <FilterPill
                        key={activatedFilter.value}
                        onClick={() =>
                            togglePillSelectedState(
                                activatedFilter.value,
                                activatedFilter.filters,
                                activatedFilter.type,
                                activatedFilter.onChange
                            )
                        }
                    >
                        <PillText
                            type="body"
                            size="tiny"
                            color={colors.BLANK_SLATE}
                        >
                            {truncateString(activatedFilter.label, 30)}{" "}
                            <Close />
                        </PillText>
                    </FilterPill>
                ))}
                {assetReceivedFilter != "" && (
                    <FilterPill
                        key="return-received-filter"
                        onClick={() => setAssetReceivedFilter("")}
                    >
                        <PillText
                            type="body"
                            size="tiny"
                            color={colors.BLANK_SLATE}
                        >
                            {truncateString(
                                "Return Received: " + assetReceivedFilter,
                                30
                            )}{" "}
                            <Close />
                        </PillText>
                    </FilterPill>
                )}{" "}
                {numFilters > 1 && (
                    <ClearAllText
                        onClick={clearAllFilters}
                        type="link"
                        size="small"
                        color={colors.WAVE_STORM}
                    >
                        Clear all filters
                    </ClearAllText>
                )}
            </FilterPillContainer>
        ) : (
            ""
        );
    };

    const clearAllFilters = () => {
        setPhaseFilters(rentalPhases);
        setAssetFilters(ASSET_FILTERS);
        setStatusFilters(STATUS_LIST);
        searchParams = new URLSearchParams();
        setSearchParams(searchParams);
        setAssetReceivedFilter("");
    };

    const phaseLabel = "Current phase " + filterLabel(phaseFilters);
    const assetLabel = "Asset rented " + filterLabel(assetFilters);
    const statusLabel = "Rental status " + filterLabel(statusFilters);

    const noDataText = () => {
        if (!rentalConfigs || !rentalConfigs.length) {
            return "Your organization hasn't set up any rentals yet. Reach out to Topanga.io if you are interested in learning more about how rentals can help your program.";
        } else if (searchTerm || selectedFilters.length) {
            return "Sorry, we didn't find any matches.";
        } else {
            return "No activity occurred within the selected time frame.";
        }
    };

    const totalResults = data?.hitAggregations
        ? data?.hitAggregations.reduce((acc, cur) => acc + cur.docCount, 0)
        : 0;

    const formatResultCount = (count: number) => {
        return count <= 999999 ? formatCount(count) : formatCount(count, true);
    };
    const endOfTable =
        page === Math.floor(data?.totalHits / rowsPerPage) + 1 ||
        (!(data?.totalHits % rowsPerPage) &&
            page === data?.totalHits / rowsPerPage);

    const conditionalRowStyles = [
        {
            when: (row) =>
                (row == highlight && rentalOpen) ||
                (row == highlight && isOpen),
            style: {
                backgroundColor: "#E5EFFF",
                userSelect: "none",
            },
        },
    ];

    const handleRowClicked = (row, event) => {
        setHighlight(row);
    };

    const onFilterOpen = () => {
        setOpenModal(true);
    };

    const onFilterClose = () => {
        setOpenModal(false);
    };

    const triggerNotifications = (item) => {
        setCancelRentalNotification(item);
    };

    const loadingCancel = () => {
        setLoading(true);
    };

    const backButtonBool = () => {
        setBackButtonClicked(true);
    };

    useEffect(() => {
        handleOnCancel();
    }, [cancelRentalNotification]);

    const handleOnCancel = () => {
        setRentalLoaded(false);
        if (isEmpty(cancelRentalNotification)) {
            return;
        } else if (cancelRentalNotification.status == "CANCELED") {
            toastSuccess("The rental was successfully canceled");
            setUpdatedRentalStatus(true);
            loadData();
            setRentalLoaded(true);
            setLoading(false);
        } else {
            toastError("An error occurred during rental cancellation");
            loadData();
            setRentalLoaded(true);
            setLoading(false);
        }
    };

    //[TEC-2049] Refactor RentalsList and subcomponents

    return (
        <>
            <SearchWrapper>
                <StyledEventSearch
                    value={searchTerm}
                    loading={dataLoading && searchTerm}
                    ref={eventSearch}
                    onChange={handleSearch}
                    searchTags={searchTags}
                    tagRows={[2, 2, 2]}
                    consumerDetail={removePrefixPropValue}
                    clearSearchTerm={clearSearchTerm}
                />
            </SearchWrapper>
            <TopWrapper ref={activityContainer}>
                <FilterLeft>
                    <TabletResponsiveFilters>
                        <FilterWrapper>
                            <Filter
                                buttonLabel={phaseLabel}
                                options={[...phaseFilters]}
                                onClear={() =>
                                    handleOnEventClear(
                                        setPhaseFilters,
                                        rentalPhases,
                                        "phase"
                                    )
                                }
                                onSave={(options: FilterOption[]) =>
                                    handleOnEventSave(
                                        setPhaseFilters,
                                        options,
                                        "phase"
                                    )
                                }
                            />
                        </FilterWrapper>
                        <Displayable displayPrimaryComponent={isTablet}>
                            <FilterWrapper firstFilter={true}>
                                <AssetReceivedFilter
                                    value={assetReceivedFilter}
                                    onSubmit={handleAssetReceivedFilterUpdate}
                                />
                            </FilterWrapper>
                        </Displayable>
                    </TabletResponsiveFilters>
                    <FilterWrapper>
                        <Filter
                            buttonLabel={statusLabel}
                            options={[...statusFilters]}
                            onClear={() =>
                                handleOnEventClear(
                                    setStatusFilters,
                                    STATUS_LIST,
                                    "status"
                                )
                            }
                            onSave={(options: FilterOption[]) =>
                                handleOnEventSave(
                                    setStatusFilters,
                                    options,
                                    "status"
                                )
                            }
                        />
                    </FilterWrapper>
                    <FilterWrapper>
                        <Filter
                            buttonLabel={assetLabel}
                            options={[...assetFilters]}
                            onClear={() =>
                                handleOnEventClear(
                                    setAssetFilters,
                                    ASSET_FILTERS,
                                    "asset"
                                )
                            }
                            onSave={(options: FilterOption[]) =>
                                handleOnEventSave(
                                    setAssetFilters,
                                    options,
                                    "asset"
                                )
                            }
                        />
                    </FilterWrapper>
                    <Displayable displayPrimaryComponent={!isTablet}>
                        <FilterWrapper firstFilter={true}>
                            <AssetReceivedFilter
                                value={assetReceivedFilter}
                                onSubmit={handleAssetReceivedFilterUpdate}
                            />
                        </FilterWrapper>
                    </Displayable>
                </FilterLeft>
                <FilterRight>
                    {data ? (
                        <Counter count={totalResults}>
                            <CounterValue
                                type="body"
                                size="medium"
                                color={colors.DEEP_BLUE_SEA}
                            >
                                {formatResultCount(totalResults)} results
                            </CounterValue>
                        </Counter>
                    ) : (
                        ""
                    )}
                </FilterRight>
            </TopWrapper>
            {activatedFilters()}
            <div style={{ paddingRight: spacing.xsmall }}>
                <RentalViusal
                    filteredAggregations={data?.hitAggregations}
                    statusFilters={statusFilters}
                    phaseFilters={phaseFilters}
                ></RentalViusal>
            </div>
            <Container shouldShowCounter={false}>
                {openModal ? (
                    <>
                        <CancelModal
                            onClick={() => setOpenModal(false)}
                            rental={clickedRental}
                            loading={loading}
                            loadingCancel={loadingCancel}
                            triggerNotifications={triggerNotifications}
                            onFilterClose={onFilterClose}
                            rentalId={rentalId}
                        ></CancelModal>
                    </>
                ) : (
                    <></>
                )}
                <RentalOverlay
                    onClickDisplay={onClickDisplay}
                    rentalId={rentalId}
                    setConsumerId={setConsumerId}
                    rentalOpen={rentalOpen}
                    onRentalClose={closeOverlay}
                    openConsumerOverlay={openConsumerOverlay}
                    onFilterOpen={onFilterOpen}
                    setFromRentalDetails={setFromRentalDetails}
                    updatedRentalStatus={updatedRentalStatus}
                    setBackButton={setBackButtonClicked}
                    width={rentalWidth}
                ></RentalOverlay>
                <OverlayTakeover
                    rentalId={rentalId}
                    consumerId={consumerId}
                    isOpen={isOpen}
                    onConsumerOpen={openOverlay}
                    closeConsumerOverlay={closeConsumerOverlay}
                    fromRentalDetails={fromRentalDetails}
                    setFromRentalDetails={setFromRentalDetails}
                    backButtonBool={backButtonBool}
                    width={panelWidth}
                ></OverlayTakeover>
                <DataTable
                    noDataComponent={<NoTableData copy={noDataText()} />}
                    columns={isDesktop ? columns : mobileColumns}
                    keyField="assetEventId"
                    data={data?.rentals || []}
                    pagination
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[50, 100]}
                    paginationServer
                    paginationTotalRows={data?.totalHits}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressPending={
                        (configsLoading || dataLoading) && !isInitiated
                    }
                    progressComponent={<ClipLoader loading />}
                    customStyles={customStyles}
                    highlightOnHover={true}
                    data-tag="allowRowEvents"
                    onRowClicked={handleRowClicked}
                    conditionalRowStyles={conditionalRowStyles}
                />
                {data?.totalHits === MAX_RETURNED_RESULTS && endOfTable ? (
                    <TooMuchDataText
                        size="medium"
                        type="body"
                        color={colors.DEEP_BLUE_SEA}
                    >
                        We love data just as much as you, but you’ve reached the
                        maximum number of items we can load into view (10,000).
                        Try using search or filters to narrow your selection or
                        contact a Topanga team member if you need additional
                        assistance.
                    </TooMuchDataText>
                ) : null}
            </Container>
        </>
    );
}
