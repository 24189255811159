/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addObservationTags = /* GraphQL */ `
  mutation AddObservationTags($input: ObservationTagInput) {
    addObservationTags(input: $input)
  }
`;
export const removeObservationTags = /* GraphQL */ `
  mutation RemoveObservationTags($input: ObservationTagInput) {
    removeObservationTags(input: $input)
  }
`;
export const addHumanLabel = /* GraphQL */ `
  mutation AddHumanLabel($input: AddHumanLabelSessionInput) {
    addHumanLabel(input: $input)
  }
`;
export const updateObservationItemCount = /* GraphQL */ `
  mutation UpdateObservationItemCount($input: ObservationItemCountInput) {
    updateObservationItemCount(input: $input)
  }
`;
export const createIngredient = /* GraphQL */ `
  mutation CreateIngredient($input: CreateIngredientInput) {
    createIngredient(input: $input) {
      id
      name
      externalId
      labels
      costCurrency
      cost
      costUOMQty
      costUOM
      updatedAt
      createdAt
    }
  }
`;
export const updateIngredient = /* GraphQL */ `
  mutation UpdateIngredient($input: UpdateIngredientInput) {
    updateIngredient(input: $input)
  }
`;
export const createMenuItem = /* GraphQL */ `
  mutation CreateMenuItem($input: CreateMenuItemInput) {
    createMenuItem(input: $input) {
      id
      name
      externalId
      categories
      recipe {
        ingredientId
        qty
        qtyUOM
        perQty
        perUOM
      }
      costCurrency
      cost
      costUOMQty
      costUOM
      updatedAt
      createdAt
    }
  }
`;
export const updateMenuItem = /* GraphQL */ `
  mutation UpdateMenuItem($input: UpdateMenuItemInput) {
    updateMenuItem(input: $input)
  }
`;
export const createMenu = /* GraphQL */ `
  mutation CreateMenu($input: CreateMenuInput) {
    createMenu(input: $input) {
      id
      menuDate
      externalId
      location {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      mealPeriod
      menuItems {
        id
        name
        externalId
        categories
        recipe {
          ingredientId
          qty
          qtyUOM
          perQty
          perUOM
        }
        costCurrency
        cost
        costUOMQty
        costUOM
        updatedAt
        createdAt
      }
      ingredients {
        id
        name
        externalId
        labels
        costCurrency
        cost
        costUOMQty
        costUOM
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateMenu = /* GraphQL */ `
  mutation UpdateMenu($input: UpdateMenuInput) {
    updateMenu(input: $input)
  }
`;
export const addIngredientToMenu = /* GraphQL */ `
  mutation AddIngredientToMenu($input: ModifyMenuWithIngredientInput) {
    addIngredientToMenu(input: $input)
  }
`;
export const removeIngredientFromMenu = /* GraphQL */ `
  mutation RemoveIngredientFromMenu($input: ModifyMenuWithIngredientInput) {
    removeIngredientFromMenu(input: $input)
  }
`;
export const addMenuItemToMenu = /* GraphQL */ `
  mutation AddMenuItemToMenu($input: ModifyMenuWithMenuItemInput) {
    addMenuItemToMenu(input: $input)
  }
`;
export const removeMenuItemToMenu = /* GraphQL */ `
  mutation RemoveMenuItemToMenu($input: ModifyMenuWithMenuItemInput) {
    removeMenuItemToMenu(input: $input)
  }
`;
export const updateOrganizationInstantForgivenessEnabled = /* GraphQL */ `
  mutation UpdateOrganizationInstantForgivenessEnabled(
    $input: UpdateOrganizationInstantForgivenessEnabledInput!
  ) {
    updateOrganizationInstantForgivenessEnabled(input: $input) {
      organizationId
      name
      slug
      externalId
      metadata {
        key
        value
      }
      scanAppUsers
      scanAppUserLimit
      instantForgivenessEnabled
      returnReceivedForgivenessEnabled
    }
  }
`;
export const updateOrganizationReturnReceivedForgivenessEnabled = /* GraphQL */ `
  mutation UpdateOrganizationReturnReceivedForgivenessEnabled(
    $input: UpdateOrganizationReturnReceivedForgivenessEnabledInput!
  ) {
    updateOrganizationReturnReceivedForgivenessEnabled(input: $input) {
      organizationId
      name
      slug
      externalId
      metadata {
        key
        value
      }
      scanAppUsers
      scanAppUserLimit
      instantForgivenessEnabled
      returnReceivedForgivenessEnabled
    }
  }
`;
export const createParentLocation = /* GraphQL */ `
  mutation CreateParentLocation($input: CreateParentLocationInput!) {
    createParentLocation(input: $input) {
      locationId
      name
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      externalId
      locations {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateParentLocation = /* GraphQL */ `
  mutation UpdateParentLocation($input: UpdateParentLocationInput!) {
    updateParentLocation(input: $input) {
      locationId
      name
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      externalId
      locations {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const archiveParentLocation = /* GraphQL */ `
  mutation ArchiveParentLocation($input: ArchiveParentLocationInput!) {
    archiveParentLocation(input: $input) {
      locationId
      name
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      externalId
      locations {
        locationId
        name
        parentId
        fullName
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
        createdAt
        updatedAt
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const archiveLocation = /* GraphQL */ `
  mutation ArchiveLocation($input: ArchiveLocationInput!) {
    archiveLocation(input: $input) {
      locationId
      name
      parentId
      fullName
      externalId
      address {
        line1
        line2
        line3
        postalCode
        country
        latitude
        longitude
        googlePlacesId
      }
      metadata {
        key
        value
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConsumer = /* GraphQL */ `
  mutation CreateConsumer($input: CreateConsumerInput!) {
    createConsumer(input: $input) {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
      createdAt
      chargeables {
        chargeableId
        vendor
      }
    }
  }
`;
export const updateConsumer = /* GraphQL */ `
  mutation UpdateConsumer($input: UpdateConsumerInput!) {
    updateConsumer(input: $input) {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
      createdAt
      chargeables {
        chargeableId
        vendor
      }
    }
  }
`;
export const deleteConsumer = /* GraphQL */ `
  mutation DeleteConsumer($input: DeleteConsumerInput!) {
    deleteConsumer(input: $input) {
      consumerId
      reuserId
      name
      phoneNumber
      email
      externalId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
      createdAt
      chargeables {
        chargeableId
        vendor
      }
    }
  }
`;
export const createAsset = /* GraphQL */ `
  mutation CreateAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
        }
      }
      metadata {
        key
        value
      }
    }
  }
`;
export const updateAsset = /* GraphQL */ `
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
        }
      }
      metadata {
        key
        value
      }
    }
  }
`;
export const deleteAsset = /* GraphQL */ `
  mutation DeleteAsset($input: DeleteAssetInput!) {
    deleteAsset(input: $input) {
      assetId
      externalId
      value
      assetType {
        assetTypeId
        name
        description
        externalId
        value
        metadata {
          key
          value
        }
      }
      metadata {
        key
        value
      }
    }
  }
`;
export const createAssetType = /* GraphQL */ `
  mutation CreateAssetType($input: CreateAssetTypeInput!) {
    createAssetType(input: $input) {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
      }
    }
  }
`;
export const updateAssetType = /* GraphQL */ `
  mutation UpdateAssetType($input: UpdateAssetTypeInput!) {
    updateAssetType(input: $input) {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
      }
    }
  }
`;
export const deleteAssetType = /* GraphQL */ `
  mutation DeleteAssetType($input: DeleteAssetTypeInput!) {
    deleteAssetType(input: $input) {
      assetTypeId
      name
      description
      externalId
      value
      metadata {
        key
        value
      }
    }
  }
`;
export const createResolution = /* GraphQL */ `
  mutation CreateResolution($input: CreateResolutionInput!) {
    createResolution(input: $input) {
      resolutionId
      type {
        likeAssetTypeResolution {
          likeAssetTypeIds
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetTypeResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
      }
      label
      useAgain
      name
      description
      metadata {
        key
        value
      }
    }
  }
`;
export const updateResolution = /* GraphQL */ `
  mutation UpdateResolution($input: UpdateResolutionInput!) {
    updateResolution(input: $input) {
      resolutionId
      type {
        likeAssetTypeResolution {
          likeAssetTypeIds
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetTypeResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
      }
      label
      useAgain
      name
      description
      metadata {
        key
        value
      }
    }
  }
`;
export const deleteResolution = /* GraphQL */ `
  mutation DeleteResolution($input: DeleteResolutionInput!) {
    deleteResolution(input: $input) {
      resolutionId
      type {
        likeAssetTypeResolution {
          likeAssetTypeIds
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetTypeResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
      }
      label
      useAgain
      name
      description
      metadata {
        key
        value
      }
    }
  }
`;
export const createLoop = /* GraphQL */ `
  mutation CreateLoop($input: CreateLoopInput!) {
    createLoop(input: $input) {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
          }
        }
        metadata {
          key
          value
        }
      }
      consumer {
        consumerId
        reuserId
        name
        phoneNumber
        email
        externalId
        metadata {
          key
          value
        }
        marketingOptIn {
          email
          phoneNumber
        }
        createdAt
        chargeables {
          chargeableId
          vendor
        }
      }
      locations {
        openedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
        closedAt {
          locationId
          name
          parentId
          fullName
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
          createdAt
          updatedAt
        }
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
              }
            }
            sameAssetResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
              }
            }
            sameAssetTypeResolution {
              resolutionTiming {
                timestamp
                duration
                roundToTime
                roundToTimezone
              }
            }
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
          }
        }
        resolutionStatus
        triggerResolutionAt
      }
      updatedAt
      metadata {
        key
        value
      }
      rentalConfig {
        rentalConfigId
        version
        name
        description
        resolutionOption
        likeAssetTypeIds
        rentalStepConfigIds
        createdAt
        updatedAt
      }
    }
  }
`;
export const closeLoop = /* GraphQL */ `
  mutation CloseLoop($input: CloseLoopInput!) {
    closeLoop(input: $input) {
      consumerId
      assetId
      loopId
    }
  }
`;
export const createWebhook = /* GraphQL */ `
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhookId
      name
      event
      endpoint
      status
      updatedAt
    }
  }
`;
export const updateWebhook = /* GraphQL */ `
  mutation UpdateWebhook($input: UpdateWebhookInput!) {
    updateWebhook(input: $input) {
      webhookId
      name
      event
      endpoint
      status
      updatedAt
    }
  }
`;
export const deleteWebhook = /* GraphQL */ `
  mutation DeleteWebhook($input: DeleteWebhookInput!) {
    deleteWebhook(input: $input) {
      webhookId
      name
      event
      endpoint
      status
      updatedAt
    }
  }
`;
export const createAssetOperation = /* GraphQL */ `
  mutation CreateAssetOperation($input: CreateAssetOperationInput!) {
    createAssetOperation(input: $input) {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const updateAssetOperation = /* GraphQL */ `
  mutation UpdateAssetOperation($input: UpdateAssetOperationInput!) {
    updateAssetOperation(input: $input) {
      assetOperationId
      isVisible
      name
      operationType
      description
      externalId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const createAssetEvent = /* GraphQL */ `
  mutation CreateAssetEvent($input: CreateAssetEventInput!) {
    createAssetEvent(input: $input) {
      assetEventId
      assetId
      assetOperation {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
        }
        updatedAt
      }
      eventTimestamp
      locationId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const updateAssetEvent = /* GraphQL */ `
  mutation UpdateAssetEvent($input: UpdateAssetEventInput!) {
    updateAssetEvent(input: $input) {
      assetEventId
      assetId
      assetOperation {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
        }
        updatedAt
      }
      eventTimestamp
      locationId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const deleteAssetEvent = /* GraphQL */ `
  mutation DeleteAssetEvent($input: DeleteAssetEventInput!) {
    deleteAssetEvent(input: $input) {
      assetEventId
      assetId
      assetOperation {
        assetOperationId
        isVisible
        name
        operationType
        description
        externalId
        metadata {
          key
          value
        }
        updatedAt
      }
      eventTimestamp
      locationId
      metadata {
        key
        value
      }
      updatedAt
    }
  }
`;
export const createManualInventoryAdjustment = /* GraphQL */ `
  mutation CreateManualInventoryAdjustment(
    $input: ManualInventoryAdjustmentInput!
  ) {
    createManualInventoryAdjustment(input: $input) {
      adjustmentId
      assetTypeId
      locationId
      reason
      adjustByAmount
      adjustToAmount
      createdAt
    }
  }
`;
export const createScanAppGroupUser = /* GraphQL */ `
  mutation CreateScanAppGroupUser($input: CreateScanAppGroupUserInput!) {
    createScanAppGroupUser(input: $input) {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
    }
  }
`;
export const createScanAppIndividualUser = /* GraphQL */ `
  mutation CreateScanAppIndividualUser(
    $input: CreateScanAppIndividualUserInput!
  ) {
    createScanAppIndividualUser(input: $input) {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
    }
  }
`;
export const updateScanAppGroupUserPassword = /* GraphQL */ `
  mutation UpdateScanAppGroupUserPassword(
    $input: UpdateScanAppGroupUserPasswordInput!
  ) {
    updateScanAppGroupUserPassword(input: $input) {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
    }
  }
`;
export const updateScanAppUserSettings = /* GraphQL */ `
  mutation UpdateScanAppUserSettings($input: UpdateScanAppUserSettingsInput!) {
    updateScanAppUserSettings(input: $input) {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
    }
  }
`;
export const deleteScanAppUser = /* GraphQL */ `
  mutation DeleteScanAppUser($input: DeleteScanAppUserInput!) {
    deleteScanAppUser(input: $input) {
      userId
      username
      accountType
      allLocationsEnabled
      enabledLocationIds
      selectedLocationId
      canChangeResolutions
      selectedResolutionIds
      lastActivity
      createdAt
      status
    }
  }
`;
export const createCharge = /* GraphQL */ `
  mutation CreateCharge($input: CreateChargeInput!) {
    createCharge(input: $input) {
      chargeId
      consumerId
      externalId
      chargeAmount
      chargeInfo
      chargeStatus
      failedReason {
        code
        message
      }
      loopId
      createdAt
      updatedAt
    }
  }
`;
export const updateChargeOutcome = /* GraphQL */ `
  mutation UpdateChargeOutcome($input: UpdateChargeOutcomeInput!) {
    updateChargeOutcome(input: $input) {
      chargeId
      consumerId
      externalId
      chargeAmount
      chargeInfo
      chargeStatus
      failedReason {
        code
        message
      }
      loopId
      createdAt
      updatedAt
    }
  }
`;
export const createPhaseChangeRentalStepConfig = /* GraphQL */ `
  mutation CreatePhaseChangeRentalStepConfig(
    $input: CreatePhaseChangeRentalStepConfigInput!
  ) {
    createPhaseChangeRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationRentalStepConfig = /* GraphQL */ `
  mutation CreateNotificationRentalStepConfig(
    $input: CreateNotificationRentalStepConfigInput!
  ) {
    createNotificationRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
      notificationType
      notificationData
    }
  }
`;
export const createChargeRentalStepConfig = /* GraphQL */ `
  mutation CreateChargeRentalStepConfig(
    $input: CreateChargeRentalStepConfigInput!
  ) {
    createChargeRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePhaseChangeRentalStepConfig = /* GraphQL */ `
  mutation UpdatePhaseChangeRentalStepConfig(
    $input: UpdatePhaseChangeRentalStepConfigInput!
  ) {
    updatePhaseChangeRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationRentalStepConfig = /* GraphQL */ `
  mutation UpdateNotificationRentalStepConfig(
    $input: UpdateNotificationRentalStepConfigInput!
  ) {
    updateNotificationRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
      notificationType
      notificationData
    }
  }
`;
export const updateChargeRentalStepConfig = /* GraphQL */ `
  mutation UpdateChargeRentalStepConfig(
    $input: UpdateChargeRentalStepConfigInput!
  ) {
    updateChargeRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRentalStepConfig = /* GraphQL */ `
  mutation DeleteRentalStepConfig($input: DeleteRentalStepConfigInput!) {
    deleteRentalStepConfig(input: $input) {
      id
      name
      description
      rentalPhase
      timing {
        timestamp
        duration
        roundToTime
        roundToTimezone
      }
      createdAt
      updatedAt
      ... on NotificationRentalStepConfig {
        notificationType
        notificationData
      }
    }
  }
`;
export const createRentalConfig = /* GraphQL */ `
  mutation CreateRentalConfig($input: CreateRentalConfigInput!) {
    createRentalConfig(input: $input) {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
    }
  }
`;
export const updateRentalConfig = /* GraphQL */ `
  mutation UpdateRentalConfig($input: UpdateRentalConfigInput!) {
    updateRentalConfig(input: $input) {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
    }
  }
`;
export const deleteRentalConfig = /* GraphQL */ `
  mutation DeleteRentalConfig($input: DeleteRentalConfigInput!) {
    deleteRentalConfig(input: $input) {
      rentalConfigId
      version
      name
      description
      resolutionOption
      likeAssetTypeIds
      rentalStepConfigIds
      createdAt
      updatedAt
    }
  }
`;
export const createRental = /* GraphQL */ `
  mutation CreateRental($input: CreateRentalInput!) {
    createRental(input: $input) {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
      }
      forgivenessDetails {
        timestamp
        reason
      }
    }
  }
`;
export const updateRentalPhase = /* GraphQL */ `
  mutation UpdateRentalPhase($input: UpdateRentalPhaseInput!) {
    updateRentalPhase(input: $input) {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
      }
      forgivenessDetails {
        timestamp
        reason
      }
    }
  }
`;
export const updateRentalStatus = /* GraphQL */ `
  mutation UpdateRentalStatus($input: UpdateRentalStatusInput!) {
    updateRentalStatus(input: $input) {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
      }
      forgivenessDetails {
        timestamp
        reason
      }
    }
  }
`;
export const completeRental = /* GraphQL */ `
  mutation CompleteRental($input: CompleteRentalInput!) {
    completeRental(input: $input) {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
      }
      forgivenessDetails {
        timestamp
        reason
      }
    }
  }
`;
export const adminCancelRental = /* GraphQL */ `
  mutation AdminCancelRental($input: AdminCancelRentalInput!) {
    adminCancelRental(input: $input) {
      rentalId
      rentalConfigId
      rentalConfigVersion
      loopId
      consumerId
      lastPhase
      status
      rentalStepIds
      rentedAssetId
      rentedAssetTypeName
      resolvingLoop {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          reuserId
          name
          phoneNumber
          email
          externalId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
          createdAt
          chargeables {
            chargeableId
            vendor
          }
        }
        locations {
          openedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
          closedAt {
            locationId
            name
            parentId
            fullName
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
            createdAt
            updatedAt
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            type {
              likeAssetTypeResolution {
                likeAssetTypeIds
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
              sameAssetTypeResolution {
                resolutionTiming {
                  timestamp
                  duration
                  roundToTime
                  roundToTimezone
                }
              }
            }
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
        rentalConfig {
          rentalConfigId
          version
          name
          description
          resolutionOption
          likeAssetTypeIds
          rentalStepConfigIds
          createdAt
          updatedAt
        }
      }
      resolvingLoopId
      receivedAssetId
      receivedAssetTypeName
      createdAt
      updatedAt
      metadata {
        key
        value
      }
      forgivenessDetails {
        timestamp
        reason
      }
    }
  }
`;
export const createRentalStep = /* GraphQL */ `
  mutation CreateRentalStep($input: CreateRentalStepInput!) {
    createRentalStep(input: $input) {
      id
      rentalId
      rentalStepConfigId
      rentalPhase
      triggerAt
      status
      statusUpdatedAt
      createdAt
      rentalStepConfigType
    }
  }
`;
export const createExport = /* GraphQL */ `
  mutation CreateExport(
    $organizationId: ID!
    $dryRun: Boolean
    $email: String
    $fromTimestamp: String
    $toTimestamp: String
    $size: Int
  ) {
    createExport(
      organizationId: $organizationId
      dryRun: $dryRun
      email: $email
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      size: $size
    ) {
      message
      id
    }
  }
`;
export const deleteExport = /* GraphQL */ `
  mutation DeleteExport($id: ID!) {
    deleteExport(id: $id) {
      message
    }
  }
`;
